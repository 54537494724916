*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: elza, sans-serif;
  font-weight: 300;
}

.font-heading {
  letter-spacing: -.025em;
}

h1 {
  letter-spacing: -.025em;
  font-family: druk, sans-serif;
  font-size: 1.875rem;
  line-height: 2.5rem;
}

@media (min-width: 640px) {
  h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
}

h2 {
  letter-spacing: -.025em;
  font-family: druk, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

h3 {
  letter-spacing: -.025em;
  font-family: druk, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

h4, h5, h6 {
  letter-spacing: -.025em;
  font-family: druk, sans-serif;
  font-size: .875rem;
  line-height: 1.25rem;
}

p.large {
  font-size: 1.5rem;
  line-height: 2rem;
}

.p2 {
  text-transform: uppercase;
  letter-spacing: 0;
  font-family: ibm-plex-mono, sans-serif;
  font-weight: 400;
}

blockquote {
  font-size: 1.125rem;
  line-height: 1.75rem;
  position: relative;
}

@media (min-width: 640px) {
  blockquote {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

blockquote:before {
  content: "";
  z-index: -10;
  height: 4rem;
  width: 4rem;
  background: url("open-quote.737d7c8d.svg") center no-repeat;
  position: absolute;
  top: -1.5rem;
  left: 0;
}

code:before, code:after {
  content: "`";
}

pre {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
}

pre code:before, pre code:after {
  content: "";
}

table {
  width: 100%;
}

table tr {
  border-top-width: 1px;
}

table th, table td {
  text-align: left;
  vertical-align: top;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-right: .75rem;
}

figure {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

figcaption {
  opacity: .6;
  margin-top: .75rem;
  font-style: italic;
  font-weight: 700;
}

img ~ figcaption {
  margin-top: -.75rem;
}

:is(.richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6, .richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr):not(:last-child) {
  margin-bottom: 1.5rem;
}

:is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h1, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h2, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h3, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h4, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h5, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h6 {
  margin-top: 3rem;
}

.richtext h2 a, .richtext h3 a, .richtext h4 a, .richtext p a, .richtext li a {
  overflow-wrap: break-word;
}

.richtext ul, .richtext ol {
  padding-left: 1.25rem;
}

.richtext ul > li {
  margin-top: .75rem;
  list-style-type: disc;
}

.richtext ol > li {
  margin-top: .75rem;
  list-style-type: decimal;
}

.embed-container iframe {
  height: 100%;
  width: 100%;
}

.preload {
  opacity: 0;
}

.preload .transition {
  transition-property: none !important;
}

.lazyload, .lazyloading {
  opacity: 0;
}

img.lazyload:not([src]) {
  visibility: hidden;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1536px;
  }
}

.button {
  text-transform: uppercase;
  letter-spacing: 0;
  border-width: 1px;
  border-color: currentColor;
  padding: .5rem 1.5rem .75rem;
  font-family: ibm-plex-mono, sans-serif;
  font-weight: 400;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: table;
}

.button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.left-4 {
  left: 1rem;
}

.top-20 {
  top: 5rem;
}

.top-4 {
  top: 1rem;
}

.z-10 {
  z-index: 10;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.row-start-3 {
  grid-row-start: 3;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-1 {
  height: .25rem;
}

.h-\[380px\] {
  height: 380px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[36rem\] {
  max-height: 36rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-20 {
  width: 5rem;
}

.w-60 {
  width: 15rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#808080\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 128 128 / var(--tw-bg-opacity));
}

.bg-\[gold\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.stroke-current {
  stroke: currentColor;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-center {
  text-align: center;
}

.font-heading {
  font-family: druk, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.underline {
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

@font-face {
  font-family: Druk;
  src: local(DrukTextWide-Bold), url("DrukTextWide-Bold.03e090be.otf") format("opentype");
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:right-0:before {
  content: var(--tw-content);
  right: 0;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-1:before {
  content: var(--tw-content);
  height: .25rem;
}

.before\:bg-current:before {
  content: var(--tw-content);
  background-color: currentColor;
}

.before\:transition:before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:content-\[\"\"\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:underline:hover {
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

.hover\:before\:bg-current:hover:before {
  content: var(--tw-content);
  background-color: currentColor;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 640px) {
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:h-\[480px\] {
    height: 480px;
  }

  .sm\:columns-2 {
    columns: 2;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:gap-x-12 {
    column-gap: 3rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-\[2\.5rem\] {
    font-size: 2.5rem;
  }

  .sm\:leading-\[2\.75rem\] {
    line-height: 2.75rem;
  }
}

@media (min-width: 768px) {
  .md\:sticky {
    position: sticky;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .md\:text-right {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:block {
    display: block;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-current {
    color: currentColor;
  }

  .lg\:before\:block:before {
    content: var(--tw-content);
    display: block;
  }
}

/*# sourceMappingURL=main.css.map */
